import React from "react"
import styled, { keyframes } from "styled-components"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Spinner = ({ size, shade = 100 }) => {
  return <Container shade={shade} />
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const animate = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${({ theme: { getColor }, shade }) => getColor(shade)};
    border-color: ${({ theme: { getColor }, shade }) => {
      const color = getColor(shade)
      return color + " transparent" + color + " transparent"
    }};
    animation: ${animate} 1.2s linear infinite;
  }
`

export default Spinner
