import React from "react"
import styled from "styled-components"
import { wrapHighlight } from "../../../utils"
import { Link } from "gatsby"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ==
//=============================
const Results = ({ hits, input }) => {
  const getType = type => {
    let label = ""
    switch (type) {
      case "WordPressAcf_heading":
        label = "Heading of an Article"
        break
      case "WordPressAcf_body":
        label = "Body of an Article"
        break
      case "download":
        label = "Downloads"
        break
      default:
        break
    }
    return label
  }
  return (
    <Container>
      {hits.map(({ article: { title, slug }, value, type, id }) => {
        const isDownload = type === "download"

        const props = isDownload
          ? { as: "a", href: slug, target: "_blank" }
          : {
              as: Link,
              to: type === "article" ? `/${slug}/` : "/" + slug + "#" + id,
            }
        return (
          <Hit key={id} {...props} state={{ searchTerm: input }}>
            <h4>
              {" "}
              {isDownload ? title + ".pdf" : "Article - "}{" "}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </h4>
            <p className="type">Found in - {getType(type)}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: wrapHighlight(
                  isDownload ? formatPdfStr(value) : value,
                  input
                ),
              }}
            />
          </Hit>
        )
      })}
    </Container>
  )
}

function formatPdfStr(string) {
  return string
    .replace(/(\r\n|\n|\r)/gm, "")
    .replace(/(-+Page \(\d+\) Break-+)/g, "<br/><br/>")
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Hit = styled.div`
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  padding-left: 0px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  width: 100%;
  text-decoration: none;
  color: black;
  border-bottom: 1px solid #ddd;
  div {
    /* white-space: pre; */
    word-break: break-word;
  }
  &:hover {
    padding-left: 10px;
  }
  h4 {
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 0;
  }
  .type {
    margin-top: 0;
    font-size: 14px;
    color: green;
    margin-bottom: 6px;
  }

  mark {
    background: ${({ theme: { getColor } }) => getColor(100)};
    color: ${({ theme: { getColor } }) => getColor(100, true)};
    border-radius: 4px;
  }
`

export default Results
