import React from "react"
import styled from "styled-components"
import Spinner from "./spinner"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Loader = ({ children, loading, shade }) => {
  return (
    <Container>
      {loading ? (
        <Center>
          <Spinner shade={shade} />
        </Center>
      ) : (
        children
      )}
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div``

const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export default Loader
