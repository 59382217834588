import React from "react"
import styled from "styled-components"
import { GoInfo } from "react-icons/go"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Summary = ({ input, hits }) => {
  return (
    <Container isBlank={input.length === 0} noResults={hits.length === 0}>
      <GoInfo />
      {input.length === 0
        ? "Please provide a search term (3 letters or more)"
        : `We found ${hits.length} results for your term '${input}'`}
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  background: ${({ noResults }) => (noResults ? "#ffdc7f" : "#84EA7D")};
  color: ${({ noResults }) => (noResults ? "#7A4308" : "#085831")};
  border-radius: 4px;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 10px;
    font-size: 22px;
  }
`

export default Summary
