import React from "react"
import styled from "styled-components"
import Inner from "../../layout/inner"
import { IoMdSearch, IoIosCloseCircle } from "react-icons/io"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const SearchBar = React.forwardRef(
  ({ value, onKeyPress, onChange, handleClear }, ref) => {
    return (
      <Container>
        <Inner>
          <SearchInputWrapper>
            <IoMdSearch size="22px" className="icon-search" />
            <Input
              ref={ref}
              value={value}
              onChange={onChange}
              onKeyPress={onKeyPress}
              placeholder="Type at least three characters to search..."
            />
            {value.length > 1 && (
              <IoIosCloseCircle className="icon-clear" onClick={handleClear} />
            )}
          </SearchInputWrapper>
        </Inner>
      </Container>
    )
  }
)

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    font-size: 26px;
    color: ${({ theme: { getColor } }) => getColor(100)};
  }
  .icon-search {
    top: 9px;
    left: 10px;
    color: #aaa;
  }
  .icon-clear {
    color: ${({ theme: { getColor } }) => getColor(100)};
    top: 0px;
    right: -1px;
    padding: 7px;
    border-radius: 0 4px 4px 0;
    transition: all 200ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: #fff;
      background-color: ${({ theme: { getColor } }) => getColor(100)};
    }
  }
`
const Container = styled.div`
  padding: 40px;
  background: #ddd;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const Input = styled.input`
  border-radius: 6px;
  padding: 10px;
  border: none;
  padding-left: 45px;
  padding-right: 20px;
  width: calc(100% - 65px);
  border: 1px solid #ddd;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme: { getColor } }) => getColor(100)};
  }
`

export default SearchBar
