import React, { useState, useEffect, useMemo } from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Inner from "../components/layout/inner"
import { graphql, useStaticQuery } from "gatsby"
import * as JsSearch from "js-search"
import Results from "../components/pages/search/results"
import SearchBar from "../components/pages/search/search-bar"
import Summary from "../components/pages/search/summary"
import Loader from "../components/shared/loader"
import { DebounceInput } from "react-debounce-input"
// import test from "../../test-data"

const SearchPage = () => {
  const {
    allWordPressAcfBody,
    allWordPressAcfHeading,
    allWordpressWpArticles,
    allWordpressWpTopics,
    allPdf,
  } = useStaticQuery(BLOCKS)

  //============INITIALIZATION============
  // Format all data types to consitent format for searching
  const bodys = useMemo(() => filterFlatten(allWordPressAcfBody), [
    allWordPressAcfBody,
  ])
  const headings = useMemo(() => filterFlatten(allWordPressAcfHeading), [
    allWordPressAcfHeading,
  ])
  const articles = useMemo(() => flattenArticles(allWordpressWpArticles), [
    allWordpressWpArticles,
  ])
  const topics = useMemo(() => flattenTopics(allWordpressWpTopics), [
    allWordpressWpTopics,
  ])
  const pdfs = useMemo(() => formatPdfs(allPdf), [allPdf])
  //============INITIALIZATION END============

  //============SEARCH============
  // set up required variables and init search
  const [input, setInput] = useState("")
  const [search, setSearch] = useState()
  const [value, setValue] = useState("")
  const [hits, setHits] = useState([])

  // ON MOUNT
  useEffect(() => {
    const dataToSearch = new JsSearch.Search("id")

    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id")
    dataToSearch.addIndex("value") // sets the index attribute for the data

    dataToSearch.addDocuments([
      ...bodys,
      ...headings,
      ...articles,
      ...topics,
      ...pdfs,
    ])
    // dataToSearch.addDocuments(test)

    setSearch(dataToSearch)
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  //============SEARCH END============

  var ref = React.useRef()
  React.useEffect(() => {
    ref.current.focus()
  }, [])

  const handleClear = () => {
    setInput("")
    setValue("")
    setHits([])
  }

  return (
    <Layout
      seo={{ title: "Search" + (input.length > 2 ? ": " + input : "") }}
      mainHeader={"Search"}
      mainContentComponent={
        <>
          <Title>Search</Title>
          <DebounceInput
            inputRef={ref}
            className="search-box-input"
            minLength={2}
            debounceTimeout={300}
            onKeyPress={e => {
              if (e.key === "Enter") ref.current.blur()
            }}
            onChange={({ target }) => {
              setHits(search.search(target.value))
              setInput(target.value)
              setValue(target.value)
            }}
            element={SearchBar}
            value={value}
            handleClear={handleClear}
          />

          <Main>
            <Inner>
              {input.length === 0 && (
                <h3>Type at least 3 characters to search</h3>
              )}
              {input.length > 2 && <h3>Search Results</h3>}
              {input.length > 0 && input.length < 3 && (
                <h3>Keep Typing to refine your search results...</h3>
              )}
              <Summary input={input} hits={hits} />
              <Loader loading={input.length > 0 && input.length < 3}>
                <Results hits={hits} input={input} />
              </Loader>
            </Inner>
          </Main>
        </>
      }
    />
  )
}

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================

function filterFlatten(acfBlocks) {
  return acfBlocks.nodes.reduce(
    (a, { article, value, internal: { type }, id }) => {
      if (!article) return a
      return [...a, { article, value, type, id }]
    },
    []
  )
}

function flattenArticles(allWordpressWpArticles) {
  return allWordpressWpArticles.nodes.reduce(
    (a, c) => [...a, { type: "article", id: c.id, value: c.title, article: c }],
    []
  )
}

function flattenTopics(allWordpressWpTopics) {
  return allWordpressWpTopics.nodes.reduce((a, { id, name, articles }) => {
    if (!articles) return a
    return [
      ...a,
      ...articles.map(article => {
        return { id, value: name, type: "topic", article }
      }),
    ]
  }, [])
}

function formatPdfs(allPdf) {
  return allPdf.nodes.reduce((a, { id, name, content: value, source_url }) => {
    return [
      ...a,
      {
        id,
        article: { title: name, slug: source_url },
        type: "download",
        value,
        name,
        source_url,
      },
    ]
  }, [])
}

const BLOCKS = graphql`
  {
    allWordPressAcfBody {
      nodes {
        value
        id
        internal {
          type
        }
        article {
          title
          slug
          id
        }
      }
    }
    allWordPressAcfHeading {
      nodes {
        value
        id
        internal {
          type
        }
        article {
          title
          slug
          id
        }
      }
    }
    allWordpressWpArticles(
      filter: { slug: { nin: ["dummy", "sub-dummy", "fake", "article-dummy"] } }
    ) {
      nodes {
        title
        id
        slug
      }
    }
    allWordpressWpTopics(
      filter: { slug: { nin: ["dummy", "sub-dummy", "fake", "article-dummy"] } }
    ) {
      nodes {
        id
        name
        slug
        articles {
          title
          id
          slug
        }
      }
    }
    allPdf {
      nodes {
        content
        name
        source_url
        id
      }
    }
  }
`

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Main = styled.div`
  padding: 40px;
  h3 {
    font-family: "Poppins", sans-serif;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    margin-top: 0;
  }
  @media only screen and (max-width: 786px) {
    padding: 20px;
    h3 {
      font-size: 20px;
    }
  }
`
const Title = styled.h2`
  margin: 70px 40px 40px 40px;
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  line-height: 1.2;
  @media (max-width: 768px) {
    margin: 50px 20px 20px 20px;
  }
`

export default SearchPage
